"use strict"; // Start of use strict

import 'wow.js';
import 'slick-carousel';

$(document).ready(function() {

  const $root = $('html, body');

  $('.navbar-toggler').on('click', function() {
    $('#navbarResponsive.collapse').parent().parent('#mainNav').toggleClass('open');
  });


  $('a.page-scroll').on('click', function () {
    const href = $.attr(this, 'href');
    $root.animate({
      scrollTop: $(href).offset().top
    }, 500, function () {
      window.location.hash = href;
    });

    return false;
  });

  $('.owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  });


  console.log("Starting slider");
  $('.slider').slick({
    vertical: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 300
  });

  // grabbing the class names from the data attributes
  const navBar = jQuery('.navbar');

  // booleans used to tame the scroll event listening a little..
  let scrolling = false,
    scrolledPast = false;

  // transition Into
  function switchInto() {
    // update `scrolledPast` bool
    scrolledPast = true;
    // add/remove CSS classes
    navBar.removeClass("unfixed-color");
    navBar.removeClass("unfixed-height");
    navBar.addClass("fixed-color");
    navBar.addClass("fixed-height");
  }

  // transition Start
  function switchStart() {
    // update `scrolledPast` bool
    scrolledPast = false;
    // add/remove CSS classes
    navBar.addClass("unfixed-color");
    navBar.addClass("unfixed-height");
    navBar.removeClass("fixed-color");
    navBar.removeClass("fixed-height");
  }


  // set `scrolling` to true when user scrolls
  jQuery(window).scroll(() => scrolling = true);
  setInterval(() => {
    // when `scrolling` becomes true...
    if(scrolling) {
      // set it back to false
      scrolling = false;
      // check scroll position
      if ($(window).scrollTop() > 150) {
        // user has scrolled > 150px from top since last check
        if ( !scrolledPast ) {
          switchInto();
        }
      } else {
        // user has scrolled back <= 150px from top since last check
        if ( scrolledPast ) {
          switchStart();
        }
      }
    }
    // take a breath.. hold event listener from firing for 100ms
  }, 100);

  $(window).scroll(function () {
    // var height = $(window).height();
    var scroll = $(window).scrollTop();
    if (scroll) {
      $(".header-hide").addClass("scroll-header");
    } else {
      $(".header-hide").removeClass("scroll-header");
    }

  });

  // Back to top button
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });
  $('.back-to-top').click(function(){
    $('html, body').animate({scrollTop : 0},1500, 'easeInOutExpo');
    return false;
  });

  // Initiate the wowjs animation library
  // new WOW().init();

  // Initiate superfish on nav menu
  // $('.nav-menu').superfish({
  //   animation: {
  //     opacity: 'show'
  //   },
  //   speed: 400
  // });

  // Mobile Navigation
  if ($('#nav-menu-container').length) {
    var $mobile_nav = $('#nav-menu-container').clone().prop({
      id: 'mobile-nav'
    });
    $mobile_nav.find('> ul').attr({
      'class': '',
      'id': ''
    });
    $('body').append($mobile_nav);
    $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars"></i></button>');
    $('body').append('<div id="mobile-body-overly"></div>');
    $('#mobile-nav').find('.menu-has-children').prepend('<i class="fa fa-chevron-down"></i>');

    $(document).on('click', '.menu-has-children i', function(_e) {
      $(this).next().toggleClass('menu-item-active');
      $(this).nextAll('ul').eq(0).slideToggle();
      $(this).toggleClass("fa-chevron-up fa-chevron-down");
    });

    $(document).on('click', '#mobile-nav-toggle', function(_e) {
      $('body').toggleClass('mobile-nav-active');
      $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
      $('#mobile-body-overly').toggle();
    });

    $(document).click(function(e) {
      var container = $("#mobile-nav, #mobile-nav-toggle");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if ($('body').hasClass('mobile-nav-active')) {
          $('body').removeClass('mobile-nav-active');
          $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          $('#mobile-body-overly').fadeOut();
        }
      }
    });
  } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
    $("#mobile-nav, #mobile-nav-toggle").hide();
  }
});
